const DarkTheme = {
  primary: "white",
  secondary: "white",
  tertiary: "black",
  quaternary: "#e6e6e6",
  accent: "gray",
  highlight: "gray",
};


export default DarkTheme;