import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import { TextField }  from "formik-mui";
// import ActionButton1 from "shared/styles/buttons/ActionButtons/ActionButton1";
import { useLocation } from "react-router";
import accountApi from "app/api/accountApi";

// interface Props {
//   className: string,
//   // username: string | null | undefined,
//   // password: string | null | undefined,
// }

interface CreateAccountPanelProps {
  className?: string,
  username?: string | undefined;
  password?: string | undefined;
};

const CreateAccountPanel = ({className, ...props}: CreateAccountPanelProps) => {
  const [ createUser ] = accountApi.useCreateAccountMutation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const registrationToken = params.get('registrationToken')
  const groupIds = params.get('groupIds')?.split(",");
  

  
  return (
    <div className={ className}>
      <Formik
        initialValues={{
          "username": props.username ?? "",
          "passwordNew": props.password ?? "",
          "passwordConfirm": "",
          "email": "",
          "givenName": "",
          "familyName": "",
          "groupIds": groupIds ? groupIds : "",
          "registrationToken": registrationToken ? registrationToken : ""
        }}
        onSubmit={ async (values) => { 
          await createUser(values);
        } }
      >
        {({values, submitForm, isSubmitting} ) => (
          <Form>
            <Field component={ TextField } id="username" title="Username" name="username" value={props.username} />
            <Field component={ TextField } id="passwordNew" type="password" title="Password" name="passwordNew" value={values.passwordNew} />
            <Field component={ TextField } id="passwordConfirm" type="password" title="Confirm Password" name="passwordConfirm" />
            <Field component={ TextField } id="email" title="Email" name="email" value={values.email} />
            <Field component={ TextField } id="givenName" title="First Name" name="givenName" />
            <Field component={ TextField } id="familyName" title="Last Name" name="familyName" />

            <section className="FormButtons">
              {/* <ActionButton1 imageData={{}} action={ () => submitForm() } >Create Account</ActionButton1> */}
              <Button
                color='primary'
                variant='contained'
                disabled={ isSubmitting }
                onClick={ () => submitForm() }
              >
                Create Account
              </Button>
            </section>
          </Form>
        )}
      </Formik>

    </div>

  );
}


export default CreateAccountPanel;