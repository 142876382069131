import Stack from "@mui/material/Stack";
import { userApi } from "app/api/userApi";
import { isCurrentUser } from "utils";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import Typography from "shared/components/StyledTypography";


type SpacerStyle = "commas" | "commas-endingAnd" | string;

interface BadgeProps {
  className?: string;
  id?: string | null;
  group?: Array<string>;
  sx?: any;
  spacerStyle?: SpacerStyle;
};



const UserBadge = ({className, id, group, spacerStyle, ...rest}: BadgeProps) => {
  const credentials = useSelector((state: RootState) => state.auth);
  const { data: user } = userApi.useGetUserByIdQuery(id, {
    skip: id == null
  });

  if (id == null) return null;
  if (user == null) return null;

  const { 
    // icon, 
    _id: userId ,
    username, 
  } = user.entities[id];

  
  const isYou = isCurrentUser(credentials, userId);

  const addTerminator = () => {
    if (group && userId) {
      
      if (group.indexOf(userId) === group.length - 1) {
        return null;
      } else if (group.indexOf(userId) === group.length - 2) {
        if (spacerStyle) {
          if (spacerStyle === 'comma') return `,${String.fromCharCode(160)}`;
          else if (spacerStyle === "commas-endingAnd" || group.length === 2) return `, and${String.fromCharCode(160)}`;
          else {
            return spacerStyle;
          }
          
        } return `,${String.fromCharCode(160)}`;
      } else return `,${String.fromCharCode(160)}`;

    }
  };
  
  return (
    <Stack className={ className } alignItems='center' alignContent='center' justifyContent='center'>
      {/* <div className="Icon">{ user.icon }</div> */}
      <Typography sx={{ color: 'text.tertiary' }} fontSize='medium' className="Username" { ...rest } >{isYou ? "You" : username }{ addTerminator() }</Typography>
    </Stack>
  )
}

export default UserBadge;