import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade"
import ListItem from "@mui/material/ListItem";
import ListButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "shared/components/StyledTypography"
import Tooltip from "shared/components/DelayedTooltip";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import Item, { Note } from "./Item";
import ClaimCheckbox from "shared/Forms/ClaimCheckbox";
import Claimants from "shared/Users/Claimants";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentItem } from "features/lists/listsSlice";
import { closeNavDrawer, toggleAddItem, toggleItemEditor, setItemEditorMode } from "features/ui/uiSlice";
import UserBadge from "shared/Users/UserBadge";

import NoteIcon from "@mui/icons-material/Note";
import LinkIcon from "@mui/icons-material/Link";
import SuggestionIcon from "@mui/icons-material/Lightbulb";
import React from "react";
import { useParams } from "react-router-dom";
import listApi from "app/api/listApi";
import { RootState } from "app/store";
import { isCurrentUser } from "utils";

import { TransitionGroup } from "react-transition-group";
import { ColorModeContext } from "contexts/ColorModeContext";
export interface ItemViewProps {
  item: Item;
  className?: string;
  listKind: string;
  listCreator: string;
};

const ItemView = ({className, listKind, listCreator, item, ...props}: ItemViewProps) => {
  const dispatch = useDispatch();
  const credentials = useSelector((state: RootState) => state.auth);  
  const [ claimItem ] = listApi.useClaimItemOnListMutation();
  const { listId } = useParams();

  const { name, claimants }: Item = item;
  let { notes, creator: itemCreator }: Item = item;

  const [ userClaimants, setUserClaimants ] = React.useState<Array<string>>(claimants);
  const [ checked, setChecked ] = React.useState(false);
  
  
  const itemCreatorIsYou = isCurrentUser(credentials, itemCreator);
  const listCreatorIsYou = isCurrentUser(credentials, listCreator);
  const allowSelfClaim = false;

  React.useEffect( () => setChecked(userClaimants != null && userClaimants.length > 0), [ userClaimants ]);

  const showSuggestion = listCreator !== itemCreator;
  
  const links = notes.filter(note => note.note.match(/https?|www\./));
  notes = notes.filter(note => !links.includes(note));


  const handleClaim = async () => {
    if (!credentials.id) {
      return;
    }

    setUserClaimants(
      (userClaimants.includes(credentials.id))
      ? [
        ...claimants.filter(claimantId => claimantId !== credentials.id),
      ]
      : [
        ...claimants.filter(claimantId => claimantId !== credentials.id),
        credentials!.id,
      ]
    );

    if (!allowSelfClaim) {
      claimItem({
        itemId: item._id,
        listId,
      })
    }
  };

  const handleItemEditing = () => {
    dispatch(setCurrentItem(item));
    dispatch(setItemEditorMode('edit'))
    // if (!isItemEditorOpen) {
    dispatch(toggleItemEditor());
    dispatch(toggleAddItem());
    // }
    dispatch(closeNavDrawer());
  };

  const userClaimedItem = Boolean(credentials.id && claimants.includes(credentials.id));

  return (
    <ListItem className={ className } disableGutters
      sx={{ transition: 'height 300ms ease-in-out'}}
    >
      
      <ListButton>
        <ClaimCheckbox 
              item={ item }
              checked={ checked }
              userClaimed={ userClaimedItem }
              handleClick={ handleClaim }
              // allowSelfClaim={ !(props.listKind === "wishlist" && isCurrentUser(credentials, owner._id)) }
        />
        <Stack onClick={ () => handleItemEditing() } flexGrow={1}>

          <Grid container>
            <Grid>
              <ListItemText 
                primary={ <Typography fontWeight='bold'>{name}</Typography> }
              />
            </Grid>
            <Grid display='flex' alignContent='center' justifyContent='center' alignItems='center' sx={{ paddingLeft: `${1}em`}}>
              <Stack flexDirection='row' >
              {/* { 
                showSuggestion
                  ? 
                    <Tooltip title={
                      <Stack flexDirection='row'>
                        { `This item was ${ listKind === 'wishlist' ? 'suggested' : 'created' } by${String.fromCharCode(160)}`}
                        <UserBadge id={ itemCreator } />
                      </Stack>
                    }>
                      <IconButton size='small'>
                        <SuggestionIcon />
                      </IconButton>
                    </Tooltip>
                  :
                    null
              } */}
              </Stack>
            </Grid>

          </Grid>

          <Box sx={{ fontSize: '0.9em', color: 'gray' }}>
            <Claimants userIds={ userClaimants } />
          </Box>

        </Stack>
        <ItemIcons 
          creator={ itemCreator }
          listKind={ listKind }
          showSuggestion={ showSuggestion }
          links={ links }
          notes={ notes }
        />
        </ListButton>
      
        {/* <StyledUserVisibility /> */}
      

    </ListItem>
  );
}

interface ItemIconProps {
  creator: string;          // The creator of the item in the list; NOT the creator of the list
  listKind: string;
  showSuggestion: boolean;
  links: Array<Note>;
  notes: Array<Note>;
};

const ItemIcons = ({ creator, listKind, showSuggestion, links, notes }: ItemIconProps) => {
  const { colorMode: [ mode, setMode ] } = React.useContext(ColorModeContext);
  
  return (
    <Stack className="Item-Icons" flexDirection='row'>
      <Fade in={showSuggestion}>
        <span>
          {
            showSuggestion
            ? <Tooltip title={
                <Stack flexDirection='row' alignItems='center'>
                  { `This item was ${ listKind === 'wishlist' ? 'suggested' : 'created' } by${String.fromCharCode(160)}`}
                  <UserBadge id={ creator }  />
                </Stack>
              }>
                <IconButton size='small'>
                  <SuggestionIcon sx={{ color: 'text.primary', opacity: mode === 'light' ? 0.8 : 1.0 }} />
                </IconButton>
              </Tooltip>
            : null
          }
        </span>
      </Fade>

      <Fade in={Boolean(links.length)}>
        <span>
          {
            links.length
            ? <Tooltip title={<Stack flexDirection='row' alignItems='center' >{<UserBadge id={creator} />}{String.fromCharCode(160)}provided a link for this item</Stack>}>
                <IconButton>
                  <LinkIcon sx={{ color: 'text.primary', opacity: mode === 'light' ? 0.8 : 1.0 }} />
                </IconButton>
              </Tooltip>
            : null
          }
        </span>
      </Fade>
          
        <Fade in={ Boolean(notes.length)}>
          <span>
            {
              notes.length  
              ? <Tooltip title={<Stack flexDirection='row' alignItems='center' >{<UserBadge id={creator} />}{String.fromCharCode(160)}provided a note for this item</Stack>}>
                  <IconButton>
                    <NoteIcon sx={{ color: 'text.primary', opacity: mode === 'light' ? 0.8 : 1.0 }} />
                  </IconButton> 
                </Tooltip>
              : null
            }
          </span>
        </Fade>
    </Stack>
  );
}






export default ItemView;