import Box from "@mui/material/Box";
import Collapse from '@mui/material/Collapse';
import Divider from "@mui/material/Divider";
import MuiList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "shared/components/StyledTypography"
import accountApi from "app/api/accountApi";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentListId } from "features/lists/listsSlice";
import { openListEditor, setListEditorMode } from "features/ui/uiSlice" 
import ThemeSelector from "HeaderThemeSelector";
import ChangePasswordPanel from "shared/Login/ChangePasswordPanel";
import { TransitionGroup } from "react-transition-group";


interface SettingsProps {
  className?: string,
}

const Settings = ({className, ...props}: SettingsProps) => {
  const dispatch = useDispatch();
  const credentials = useSelector((state: RootState) => state.auth);
  const { data: lists } = accountApi.useGetAccountListsQuery(credentials.id);
  
  if (lists == null) return null;

  const handleListSelect = (listId: string) => {
    dispatch(setCurrentListId(listId));
    dispatch(setListEditorMode('edit'));
    dispatch(openListEditor());
  } 
  
  let userLists = lists?.filter((listDetails: any) => listDetails.creator === credentials.id)  
  
  return (
    <Box className={ className }>
    {/* style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}  */}
      <Stack>
        {/* <h1 >User Profile Management Coming Soon! Thank you for waiting patiently.</h1> */}
      </Stack>

      <Stack justifyContent='center' alignItems='center'>
        <ThemeSelector />
        <ChangePasswordPanel />
      </Stack>

      <Divider component='div' role='presentation'>
        <Typography variant='h5' textAlign='center' padding={2}>Lists</Typography>
      </Divider>

      <Stack alignItems='center'>

        <Box 
          sx={{ 
            // backgroundColor: 'divider', 
            p: 2 
          }}
        >
          <MuiList className="User Lists" 
            sx={{ 
              // backgroundColor: 'background.paper' 
            }}>
            <TransitionGroup>
            {
              userLists.map((details: any) => (
                <Collapse key={details._id}>
                  <ListItem key={details._id}>
                    <ListItemButton onClick={() => handleListSelect(details._id)}>
                      <ListItemText>
                      <Typography >{details.name}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Collapse>
              ))
            }
            </TransitionGroup>
          </MuiList>
        </Box>

      </Stack>

    </Box>
  )
}


export default Settings;