import React from "react";
import Stack from "@mui/material/Stack";

import Checkbox from "@mui/material/Checkbox";
import Item from "shared/Lists/Items/Item";
import { useParams } from "react-router";
import listApi from "app/api/listApi";
import Tooltip from "shared/components/DelayedTooltip";
import UserBadge from "shared/Users/UserBadge";



interface ClaimantCheckBoxProps {
  className?: string;
  checked: boolean;
  item: Item;
  userClaimed: boolean;
  allowSelfClaim?: boolean | null;
  handleClick: () => void; 
}

const ClaimantCheckbox = ({className, checked, handleClick, item, userClaimed, ...props}: ClaimantCheckBoxProps) => {


  return (
    <Tooltip placement="right"
      title={ <Stack flexDirection='row'>{ !userClaimed ? 'Claim' : 'Unclaim'} this item</Stack> }>
      <Checkbox className={ className } 
        edge="start"
        id={ item._id }
        checked={ checked }
        onClick={ handleClick }
      />
    </Tooltip>
  );
};

export default ClaimantCheckbox;