import { PaletteMode } from "@mui/material";
import { amber, grey, deepOrange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { christmasBlue, christmasGold, christmasRed, nightSky } from "./palette/colors";

const BrightTheme = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: christmasGold,
        divider: christmasGold.main,
        text: {
          primary: nightSky.main,
          secondary: grey[800],
          tertiary: christmasGold.main,
        },
        typography: {
          primary: christmasBlue.main,
          secondary: "#fff"
        },
        svg: {
          primary: '#eee',
          secondary: grey[500],
        },
        link: {
          primary: "#fff",
          secondary: "#fff"
        }
      }
      : {
          // palette values for dark mode
          // bgColor: nightSky.main,
          primary: christmasRed,
          divider: grey['A200'],
          background: {
            default: '#fff',
            paper: christmasRed.main,
          },
          text: {
            primary: '#fff',
            secondary: deepOrange[300],
            tertiary: christmasRed.main,
          },
          typography: {
            primary: "#fff",
            secondary: "#fff"
          },
          svg: {
            secondary: deepOrange[300],
          },
          link: {
            primary: "#fff",
            secondary: "#fff"
          }
        }),
  },
});

export default BrightTheme;
