import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import AvatarGroup from "@mui/material/AvatarGroup";
import Stack from "@mui/material/Stack";
import UserBadge from "shared/Users/UserBadge";
import Tooltip from "shared/components/DelayedTooltip";
import Fade from "@mui/material/Fade";
import { TransitionGroup } from "react-transition-group";

interface ClaimantProps {
  className?: string;
  userIds: Array<string>;
}

const Claimants = (props: ClaimantProps) => {
  const { userIds } = props;


  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // event.preventDefault();
  }

  return(
    <Stack className={props.className} flexDirection='row'>
      {
        userIds?.length
          ?
          <Tooltip 
            placement="right"
            title={
              <Stack flexDirection='row' 
                alignItems='center'
                sx={{ '.MuiTooltip-popper': {color: 'white'} }}
              >
                This item was claimed by{String.fromCharCode(160)}{userIds.map(id => <UserBadge key={ id } id={id} group={userIds} spacerStyle="commas-endingAnd"/> )}
              </Stack>
            }>
              <span>
                <AvatarGroup sx={{ '.MuiChip-filled': { marginRight: '10px'} }}>
                  <TransitionGroup>
                    { props.userIds.map((id) => (
                        <Fade key={id}>
                          <Chip
                            onClick={ handleClick }
                            label={ 
                            <UserBadge id={ id } /> 
                        }/>
                        </Fade>
                    ))}
                  </TransitionGroup>
                </AvatarGroup>
              </span>
            </Tooltip>
          :
            null
      }
    </Stack>
  )
}


export default Claimants;