import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useFormikContext } from "formik";
import CircularProgress from "@mui/material/CircularProgress";

import SendIcon from "@mui/icons-material/Send";

interface AddInputProps {
  className?: string,
  userid?: string | null,
  listid?: string,
  isActive?: boolean,
  disabledSend?: boolean,
};

const AddItemInput = ({className, ...props}: AddInputProps) => {
  const { submitForm, isSubmitting, } = useFormikContext();
  if (props.userid == null || props.listid == null) return null;

  return (
    <Stack className={ className } 
      flexDirection='row' 
      width={'100%'}
      position='absolute'
      bottom={0}
    >
      <Toolbar sx={{width: "100%"}}>
        <Field
          id="itemToAdd" 
          component={ TextField }  
          name="itemToAdd"
          placeholder={'Add new item'}
          sx={{
            flexGrow: 1,
          }}
        />
        <Button
          // edge='end'
          sx={{ paddingTop: 2, paddingBottom: 2, marginLeft: 2}}
          variant="contained"
          disableElevation
          onClick={ () => submitForm() }
          size='large'
          color={ props.disabledSend ? 'inherit' : 'primary'}
          disabled={ props.disabledSend }
        >
          {
            !isSubmitting
              ? <SendIcon />
              : <CircularProgress />
          }
        </Button>
        <button hidden disabled={ props.disabledSend } type="submit">Add</button>
      </Toolbar>
    </Stack>
  )
}

export default AddItemInput;