const DarkTheme = {
  primary: "#1C1B22",
  secondary: "#2B2A33",
  tertiary: "#F0F0F0",
  quaternary: "#2B2A33",
  accent: "#00AFC7",
  highlight: "#23A9F2",
};


export default DarkTheme;