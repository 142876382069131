import User from "shared/Users/User";

export interface Note {
  note: string;
  id: string;
}

export interface Item {
  _id: string;
  name: string;
  notes: Array<Note>;
  creator: string;
  owner: User;
  status: string;
  claimants:  Array<string>;
  disallowedViewers: Array<string>;
}

export interface UninstantiatedItem {
  _id: undefined;
  name: string | undefined | null;
  notes: undefined;
  creator: string | undefined | null;
  owner: undefined;
  status: undefined;
  claimants:  undefined;
  disallowedViewers: undefined;
}

export const newItem = (name: string | undefined) => {
  return {
    name: name ? name : "",
    _id: undefined,
    notes: undefined,
    claimants: undefined,
    disallowedViewers: undefined,
    creator: undefined,
    owner: undefined,
    status: undefined
  }
}


export default Item;