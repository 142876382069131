import Stack from "@mui/material/Stack";
import UserBadge from "shared/Users/UserBadge";
import { Field } from "formik";
import { Checkbox } from "formik-mui";


interface UserCheckboxProps {
  userId: string
  className?: string,
  name: string
};

const UserCheckbox = ({className, userId, name, ...props}: UserCheckboxProps) => {

  return(
    <Stack className={ className } key={ userId } flexDirection='row' alignItems='center'>
      <Field component={ Checkbox } type="checkbox" id={ userId } name={ name } value={ userId }/>
      {/* <Field type="checkbox" id={ userId } name={ name } value={ userId }/> */}
      <label htmlFor={ userId }><UserBadge id={ userId }/></label>
    </Stack>
  );
}

export default UserCheckbox;