import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Item, { newItem, UninstantiatedItem }  from "shared/Lists/Items/Item";



interface ListState {
  currentListId: string | null,
  currentItem: Item | UninstantiatedItem,
  currentGroupId: string | null,
}

const initialState: ListState = {
  currentListId: null,
  currentItem: newItem(undefined),
  currentGroupId: null,
}


const listsSlice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    setCurrentItem(state, action: PayloadAction<Item | UninstantiatedItem>) {
      state.currentItem = action.payload;
    },

    setCurrentListId(state, action: PayloadAction<string>) { 
      state.currentListId = action.payload;
    },


    setCurrentGroupId(state, action: PayloadAction<string>) {
      state.currentGroupId = action.payload;
    }

    // firstList(state) {
    //   state.currentList = 
    // }
    
  }
});


export const { setCurrentListId, setCurrentItem, setCurrentGroupId } = listsSlice.actions;

export default listsSlice.reducer;