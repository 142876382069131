import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-mui";
import ActionButton from "shared/buttons/ActionButton";
import accountApi from "app/api/accountApi";
import ChangePasswordSchema from "shared/validation/ChangePasswordSchema";
import Typography from "shared/components/StyledTypography";
import Divider from "@mui/material/Divider";
import { FormHelperText } from "@mui/material";
import { ColorModeContext } from "contexts/ColorModeContext";
// import { useToasts } from "react-toast-notifications";


interface ChangePasswordProps {
  className?: string;
};


const ChangePasswordPanel = ({className, ...props}: ChangePasswordProps) => {
  // const  { addToast } = useToasts();
  const { colorMode: [ mode, setMode ] } = React.useContext(ColorModeContext);
  const [ updatePassword ] = accountApi.useUpdatePasswordMutation();

  return(
    <>
    <Divider component='div' role='presentation'>
      <Typography variant='h5' padding={2}>Manage Password</Typography>
    </Divider>
    <Stack className={ className } alignItems='center'>
      <Formik
        initialValues={{
          passwordOld: "",
          passwordNew: "",
          passwordConfirm: ""
        }}
        onSubmit={ async (values, { resetForm } ) => {
          await updatePassword(values);
          resetForm();
          // addToast("Password succesfully changed!", {
          //   "appearance": 'success',
          //   autoDismiss: true,
          // })
        } }
        validationSchema={ ChangePasswordSchema }
      >
        {({ errors, submitForm }) => (
          <Form>
            <Stack spacing={2} width='320px' sx={{ 
              // '.MuiFormControl-root': {
              //   marginTop: "10px"
              // },
              // // root: { paddingTop: '10px'  }
              // 'button': { marginTop: '20px' }
              p: 3,
              backgroundColor: mode === 'dark' ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.2)",
              borderRadius: 3,
            }}>
              <Field component={ TextField } type="password" id="passwordOld" label='Current Password' title="Current Password" name="passwordOld" />
              <Field component={ TextField } type="password" id="passwordNew" label='New Password' title="New Password" name="passwordNew" />
              <Field component={ TextField } type="password" id="passwordConfirm" label='Confirm Password' title="Confirm Password" name="passwordConfirm" />
              <button style={{ display: "none" }} type="submit" />
              <Button variant='outlined'
                onClick={ async () => { 
                if (Object.keys(errors).length > 0) {
                  let errorMessage = Object.values(errors);
                  // addToast(errorMessage, {
                  //   appearance: 'error',
                  //   autoDismiss: true,
                  // })
                }
                await submitForm();              
              } } >
                Submit
              </Button>

            </Stack>
          </Form>
        )}


      </Formik>
    </Stack>
    </>
  )
}


export default ChangePasswordPanel