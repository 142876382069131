import { PaletteMode } from "@mui/material";
import { createContext, useState } from "react";


export const ThemeNames = ['default', 'christmas', 'christmas2', 'bright'] as const;
export type ThemeName = typeof ThemeNames[number];

export interface ColorModeState {
  colorMode: [ PaletteMode, (mode?: PaletteMode | undefined) => void];
  userTouched: [ boolean, (touched: boolean) => void];
  userThemeName: [ ThemeName, (name: ThemeName) => void];
};

export const ColorModeContext = createContext<ColorModeState>({
  colorMode: ['light', (mode?: PaletteMode) => { throw new Error("Initialization Error: Must Initialize ColorMode Context to have setter functions")}],
  userTouched: [false, (touched: boolean) => { throw new Error("Initialization Error: Must Initialize ColorMode Context to have setter functions")}],
  userThemeName: [ 'default', (name: ThemeName) => { throw new Error("Initialization Error: Must Initialize ColorMode Context to have setter functions")}]
});


export interface ColorModeProviderProps {
  children: JSX.Element;
};

const ColorModeProvider =  ({ children }: ColorModeProviderProps) => {
  const [ colorMode, setColorMode ] = useState<PaletteMode>('light');
  const [ touched, setTouched ] = useState(false);
  const [ themeName, setThemeName ] = useState<ThemeName>('default');

  const toggleColorMode = (mode?: PaletteMode | undefined) => {
    if (mode) {
      setColorMode(mode);
    } else {
      setColorMode(
        colorMode === 'light' ? 'dark' : 'light'
      );
    }
  };

  const colorModeTuple: [ PaletteMode, (mode?: PaletteMode | undefined) => void ] = [colorMode, toggleColorMode];
  const userTouchedTuple: [ boolean , (touched: boolean) => void ] = [ touched, setTouched ];
  const userThemeTuple: [ ThemeName, (name: ThemeName) => void] = [ themeName, setThemeName ];

  const store = {
    colorMode: colorModeTuple,
    userTouched: userTouchedTuple,
    userThemeName: userThemeTuple,
  };

  return (
    <ColorModeContext.Provider value={ store }>
      { children }
    </ColorModeContext.Provider>
  ) 
};

export default ColorModeProvider;