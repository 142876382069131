import PaletteColor from "./PaletteColor";

export const nightSky: PaletteColor = {
  main: "#00063d",
  contrastText: '#fff',
};

export const blueZodiac: PaletteColor = {
  main: "#0b0e33",
  contrastText: '#fff'
};

export const christmasGold: PaletteColor = {
  main: "rgb(225, 165, 0)",
};

export const christmasBlue: PaletteColor = {
  main: "#08116a",
};

export const christmasRed: PaletteColor = {
  // main: "#CC231E",
  main: "#780300",
};

export const christmasWhite: PaletteColor = {
  main: "#fff",
  contrastText: christmasRed.main,
};