import Typography from "shared/components/StyledTypography";

export interface EmptyListProps {};

const EmptyList = (props: EmptyListProps) => {
  return( 
    <Typography padding={2}>
      There are no items in this list. Add some to get started.
    </Typography>
  );
}

export default EmptyList;