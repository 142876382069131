import React from "react";
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import MuiList from "@mui/material/List";
import ItemView from "./Items/ItemView";
import EmptyList from "./EmptyList";
import List from "./List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Slide from "@mui/material/Slide";
import Fade from '@mui/material/Fade';
import Typography from "shared/components/StyledTypography";
import { TransitionGroup } from "react-transition-group"



interface ListingProps {
  className?: string;
  data: List;
  isFetching?: boolean;
}

const Listing = ({className, data, isFetching, ...props}: ListingProps) => {
  if (data == null) return null;
  if (!data.items.length) {
    return <EmptyList />
  }

  return (
    <Box className={ className }>
      <MuiList dense sx={{ width: "100%", bgColor: 'background.paper'}} >
        <TransitionGroup>
        {
          data?.items.map((item, index) => (
            <Collapse key={ item._id } >
              <ItemView key={ item._id } listKind={ data.kind } item={ item } listCreator={ data.creator } />
              { index !== data.items.length - 1 ? <Divider variant='middle' component="li" /> : null }
            </Collapse>
          ))
        }
        </TransitionGroup>
        {  
          <Fade in={isFetching}>
            <ListItem>
              <ListItemIcon sx={{ minWidth: '32px' }}>
                <CircularProgress size={15} />
              </ListItemIcon>
              <ListItemText>
                <Typography fontWeight='bold'>Refreshing</Typography>
              </ListItemText>
            </ListItem> 
          </Fade>
        }
      </MuiList>  
  </Box>
  );
}


export default Listing;