import Box from "@mui/material/Box";
import { Select } from "formik-mui";
import MenuItem from "@mui/material/MenuItem"

import { Field, Formik, Form } from "formik"
import { FunctionComponent } from "react"
import { SelectorOption } from "shared/Forms/Selector";
import accountApi from "app/api/accountApi";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { isLoggedIn } from "utils";
import { ColorModeContext, ThemeName } from "contexts/ColorModeContext";
import React from "react";


const themeOptions: Array<SelectorOption> = [
  { name: "Default", value: "default" },
  // { name: "High Contrast", value: "highContrastLight" },
  { name: "Christmas", value: "christmas" },
  { name: "Christmas 2", value: "christmas2" },
];

interface ThemeSelectorProps {
  className?: string;
};

const ThemeSelector = (props: ThemeSelectorProps) => {
  const { 
    colorMode: [ mode, setMode ],
    userThemeName: [ userThemeName, setThemeName ] 
  } = React.useContext(ColorModeContext);
  const credentials = useSelector((state: RootState) => state.auth);
  const { data: userSettings } = accountApi.useGetAccountSettingsQuery(credentials, {
    skip: !isLoggedIn(credentials)
  });

  const [ updateSettings ] = accountApi.useUpdateAccountSettingsMutation();

  return(
    <Box minWidth='240px' sx={{ 
      m: 2,
      p:2,
      backgroundColor: mode === 'dark' ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.2)",
      borderRadius: 2,
      '.MuiFormControl-root': {
        width: `${100}%`
      }
    }}>
      <Formik
        enableReinitialize
        initialValues={{
          "theme": "default"
        }}
        onSubmit={
          async (values) => {
            setThemeName(values.theme as ThemeName);
            await updateSettings(values);
          }
        }
      >
        {({values, submitForm}) => (
          <Form>
            <Field component={ Select} 
              value={userSettings?.theme ?? 'default'} 
              name="theme" 
              label='Select Theme' 
              id="Theme"
              onChange={ () => submitForm() }
            >
            {
              themeOptions.map(option => (
                <MenuItem key={option.value}
                  value={option.value}
                >
                  {option.name}
                </MenuItem>
              ))
            }
            </Field>
          </Form>
        )}
      </Formik>
    </Box>
  )

}

export default ThemeSelector;