import { PaletteMode } from "@mui/material";
import { grey, deepOrange } from "@mui/material/colors";
import { christmasBlue, christmasGold, christmasRed, christmasWhite, blueZodiac } from "./palette/colors";

const ChristmasTheme = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: christmasRed,
        divider: christmasWhite.main,
        background: {
          // default: "#fff",
          paper: christmasRed.main,
        },
        text: {
          primary: christmasWhite.main,
          secondary: deepOrange[200],
        },
        typography: {
          primary: christmasWhite.main,
          secondary: "#fff"
        },
        svg: {
          primary: '#eee',
          secondary: blueZodiac.main,
        },
        link: {
          primary: "#fff",
          secondary: "#fff"
        }
      }
      : {
          // palette values for dark mode
          // bgColor: nightSky.main,
          primary: christmasRed,
          divider: grey['A200'],
          background: {
            default: '#fff',
            paper: blueZodiac.main,
          },
          text: {
            primary: '#fff',
            secondary: deepOrange[200],
          },
          typography: {
            primary: "#fff",
            secondary: "#fff"
          },
          svg: {
            primary: "#fff",
            secondary: christmasRed.main,
          },
          link: {
            primary: "#fff",
            secondary: "#fff"
          }
        }),
  },
});


export default ChristmasTheme;