import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import Stack from "@mui/material/Stack";
import MuiList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Link from "@mui/material/Link";
import { TextField } from "formik-mui";
import { Field, Formik, Form } from "formik";
import Toolbar from "@mui/material/Toolbar";
import Typography from "shared/components/StyledTypography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import BackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import SubmitIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import { newItem, Note } from "./Item";
import UserCheckbox from "shared/Forms/UserCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import TextFieldArray from "shared/Forms/TextFieldArray";
import { closeItemEditor, showAddItem } from "features/ui/uiSlice";
import listApi from "app/api/listApi";
import { isCurrentUser } from "utils";
import { setCurrentItem } from "features/lists/listsSlice";
import ItemSchema from "shared/validation/ItemSchema";
import Tooltip from "shared/components/DelayedTooltip";

import CircularProgress from "@mui/material/CircularProgress";

type ItemAction = 'Delete' | 'Add' | 'Update';

const linkPattern =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
// const domainPattern = /^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$/
// const domainPattern = /(?:http[s]*\:\/\/)*(.*?)\.([^\/]{2,3})*/gi
const domainPattern = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))/gi;

interface ItemEditorProps extends SwipeableDrawerProps {
  className?: string;
}

const ItemEditor = ({ className, ...props }: ItemEditorProps) => {
  const dispatch = useDispatch();
  const credentials = useSelector((state: RootState) => state.auth);
  const { currentListId, currentItem } = useSelector(
    (state: RootState) => state.lists
  );
  const itemEditorMode = useSelector((state: RootState) => state.ui.itemMode);

  const [removeItem] = listApi.useRemoveItemFromListMutation();
  const [addItem] = listApi.useAddItemToListMutation();
  const [updateItem] = listApi.useEditItemOnListMutation();

  const [itemAction, setItemAction] = React.useState<ItemAction | null>(null);

  const { data: listData } = listApi.useGetListByIdQuery(currentListId, {
    skip: currentListId == null,
  });

  const [dialog, setDialog] = React.useState<string | null>(null);

  // const user = userApi.useGetUserByIdQuery(currentItem.creator, {
  //   skip: !currentItem
  // });

  const dialogOpen = Boolean(dialog);

  if (currentListId == null) return null;
  if (currentItem == null) return null;
  // if (!user) return null;

  // const { username } = user;

  // const creator = currentItem.creator ? currentItem.creator : credentials.id;

  // const creator = credentials.id;
  const isYou = isCurrentUser(credentials, currentItem.creator);

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleOpenDialog = (name: string) => {
    setDialog(name);
  };

  const handleDialogTitle = (): string => {
    if (dialog === "delete") {
      return "Delete Item?";
    } else if (dialog === "cancel") {
      return "Discard Item Changes?";
    }
    return "";
  };

  const handleDialogText = (): string => {
    if (dialog === "delete") {
      return "Delete this item from your list. This means this item will no longer by viewable by you and other users.";
    } else if (dialog === "cancel") {
      return "Leave the Item Editor and discard any changes you have made to the item. This means that any changes to the name, notes, or allowed users will not be applied";
    }
    return "";
  };

  const handleDialogClick = (): ((
    event: React.MouseEvent<HTMLButtonElement, Event>
  ) => void) => {
    if (dialog === "delete") {
      return (event: React.MouseEvent<HTMLButtonElement, Event>) => {
        handleCloseDialog();
        removeItem({ listId: currentListId, itemId: currentItem._id });
        // dispatch(closeItemEditor());
        handleCloseItemEditor();
      };
    } else if (dialog === "cancel") {
      // Do nothing since it will return the the function below, which is what we want anyway
      // We keep this here in case we need to create further dialogs later and then we will move the function below up here
    }

    return (event: React.MouseEvent<HTMLButtonElement, Event>) => {
      handleCloseDialog();
      dispatch(closeItemEditor());
    };
  };

  const handleDialogActionButtonText = (): string => {
    if (dialog === "delete") {
      return "Delete";
    } else if (dialog === "cancel") {
      return "Discard Changes";
    }
    return "";
  };

  const handleCloseItemEditor = () => {
    dispatch(closeItemEditor());
    dispatch(showAddItem());
    dispatch(setCurrentItem(newItem(undefined)));
    setItemAction(null);
  };

  // const handleSaveItem()

  const handleLinks = (note: string): JSX.Element | string => {
    let domainMatches = note.match(domainPattern) ?? [];
    let urlMatches = note.match(linkPattern) ?? [];

    domainMatches = Array.from(new Set(domainMatches));
    urlMatches = Array.from(new Set(urlMatches));

    console.log(domainMatches);
    console.log(urlMatches);
    
    if (domainMatches.length !== urlMatches.length) {
      // If the number of domain and url matches are not equal, there must be some issue so display the original text
      return note;
    }
    
    let parts: Array<string | JSX.Element> = [];

    parts = domainMatches.map((link, idx) =>  {
      const [stringText, ...rest] =  note.split(linkPattern);
      const createdLink = (
        <Link
          color='text.secondary'
          href={urlMatches[idx]}
          target="_blank"
          rel="noreferrer"
        >
          {link}
        </Link>
      );

      return [stringText, createdLink];
    })
    .flatMap(parts => parts);

    return (
      <span>
        {
          parts.map(part => part)
        }    
      </span>
    );
  };

  return (
    <SwipeableDrawer {...props}>
      <Formik
        enableReinitialize
        initialValues={{
          listId: currentListId,
          itemId: currentItem ? currentItem._id : undefined,
          name: currentItem ? currentItem.name : "",
          notes: currentItem?.notes ? currentItem.notes : [],
          disallowedViewers: currentItem?.disallowedViewers
            ? currentItem.disallowedViewers
            : [],
          creator: currentItem.creator,
          // owner: username
          owner: "",
        }}
        validationSchema={ItemSchema}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting }) => {
          if (itemEditorMode === "create") {
            await addItem(values);
          } else {
            await updateItem(values);
          }
          setSubmitting(false);
          handleCloseItemEditor();
          handleCloseDialog();
        }}
      >
        {({ values, isSubmitting, touched, submitForm }) => (
          <Form>
            <Box className={className} margin={2}>
              <Box className="General">
                <Field
                  component={TextField}
                  disabled={!isYou}
                  type="text"
                  label="Name"
                  id="name"
                  name="name"
                />
                {/* TODO: Look into this to make sure that this works */}
                {/* <Field component={ TextField } type="text" disabled label="Owner" id="Owner" name="owner"> */}
                {/* <UserBadge id={ currentItem.creator } /> */}
                {/* { currentItem.owner } */}
                {/* </Field> */}
                <Field
                  component={TextField}
                  type="text"
                  disabled
                  label="Creator"
                  id="creator"
                  name="creator"
                  style={{ display: "none" }}
                >
                  {/* <UserBadge id={ currentItem.creator } /> */}
                  {currentItem.creator}
                </Field>
              </Box>
              <Box className="Notes">
                {isYou ? (
                  <TextFieldArray
                    useRemove={isYou}
                    useAdd={isYou}
                    // freezeValues={ !isYou }
                    toList={!isYou}
                    id="notes"
                    title="Notes"
                    name="notes"
                    values={values.notes}
                    targets={[
                      {
                        title: "Notes",
                        name: "note",
                        kind: "text",
                      },
                    ]}
                  />
                ) : (
                  <Box className="Non-User-Notes">
                    <Typography variant="h5">Notes</Typography>
                    <MuiList
                      sx={{
                        listStyleType: "disc",
                        pl: 4,
                        listStylePosition: "outside",
                      }}
                    >
                      {values.notes.map((note: Note, idx) => (
                        <ListItem
                          key={idx}
                          disableGutters
                          sx={{
                            display: "list-item",
                            textIndent: `${-3.5}em`,
                            paddingLeft: `${2}em`,
                          }}
                        >
                          <ListItemIcon>{/* <CircleIcon /> */}</ListItemIcon>
                            {note.note.search(linkPattern) > -1 ? (
                              <span>{handleLinks(note.note)}</span>
                            ) : (
                              note.note
                            )}
                          </ListItem>
                        ))}
                    </MuiList>
                  </Box>
                )}
              </Box>
              {isYou ? (
                <Box className="Disallowances">
                  <Typography variant="h5" className="FieldTitle">
                    Hide from
                  </Typography>
                  <Grid className="UserOptions" container spacing={2}>
                    { listData.users
                      .filter((user: any) => user.userid !== credentials.id)
                      .map((user: any, index: number) => (
                        <Grid md={3} xs={4} key={`user.userid${index}`}>
                          <UserCheckbox
                            key={`user.userid${index}`}
                            name={"disallowedViewers"}
                            userId={user.userid}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              ) : null}
              <Toolbar className="EditorAction" disableGutters>
                <Stack flexDirection="row">
                  <Tooltip
                    title={<Box>Exit Item {isYou ? "Editor" : "Viewer"}</Box>}
                  >
                    <Button
                      disableElevation
                      variant="contained"
                      sx={{ m: 1 }}
                      startIcon={<BackIcon />}
                      onClick={() => {
                        if (Object.keys(touched).length) {
                          handleOpenDialog("cancel");
                        } else {
                          handleCloseItemEditor();
                        }
                      }}
                    >
                      Back
                    </Button>
                  </Tooltip>
                  {
                  isYou ? (
                    <Tooltip
                      title={
                        <Box>
                          {itemEditorMode === "create"
                            ? "Add new Item"
                            : "Edit current item"}
                        </Box>
                      }
                    >
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        variant="contained"
                        sx={{ m: 1 }}
                        startIcon={ (isSubmitting && itemAction !== 'Delete') ? <CircularProgress size={20}/> : <SubmitIcon /> }
                        onClick={ () => {
                          const action = itemEditorMode === 'create' ? 'Add' : 'Update';
                          setItemAction(action);
                          submitForm();
                        }}
                      >
                        {itemEditorMode === "create" ? "Add Item" : "Save"}
                      </Button>
                    </Tooltip>
                  ) : null
                  }
                  {itemEditorMode === "create" ? (
                    <Tooltip title={<Box>Cancel Item</Box>}>
                      <Button
                        startIcon={<CancelIcon />}
                        disabled={isSubmitting}
                        onClick={() => {
                          dispatch(closeItemEditor());
                        }}
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                  ) : isYou ? (
                    <Tooltip title={<Box>Delete Item</Box>}>
                      <Button
                        disableElevation
                        disabled={isSubmitting}
                        variant="contained"
                        sx={{ m: 1 }}
                        startIcon={ (isSubmitting && itemAction === 'Delete') ? <CircularProgress size={20}/> : <DeleteIcon /> }
                        onClick={() => {
                          if (currentItem._id) {
                            setItemAction('Delete');
                            handleOpenDialog("delete");
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  ) : null}
                </Stack>
              </Toolbar>
            </Box>
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {handleDialogTitle()}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {handleDialogText()}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClick()}>
                  {handleDialogActionButtonText()}
                </Button>

                <Button onClick={handleCloseDialog}>Cancel</Button>

                <Button onClick={() => submitForm()} autoFocus>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </SwipeableDrawer>
  );
};

export default ItemEditor;
