import Box from "@mui/material/Box";
import Menu,  { MenuProps } from "@mui/material/Menu";
// import MenuList, { MenuListProps } from  "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import Tooltip from "@mui/material/Tooltip";
import Tooltip from "shared/components/DelayedTooltip";


import AccountIcon from "@mui/icons-material/AccountCircle";
import SettingIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import accountApi from "app/api/accountApi";
import { useNavigate } from "react-router-dom";

interface UserMenuProps extends MenuProps {};



const UserMenu = (props: UserMenuProps) => {
  const [ logout ] = accountApi.useLogoutMutation();
  const navigate = useNavigate();

  const handleClickAccount = () => {
    navigate("/profile");
  };
  
  const handleClickSettings = () => {
    navigate("/settings");
  };

  const handleLogout = async () => {
    await logout(null);
  };

  return (
    <Menu {...props } >

      <Tooltip title={ <Box>Account Setting are currently disabled</Box> }  placement='left'>
          <MenuItem disabled onClick={ handleClickAccount }>
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            <ListItemText>
              Account
            </ListItemText>
          </MenuItem>
      </Tooltip>

      <Tooltip title={ <Box>Change your Settings</Box> } placement='left'>
          <MenuItem onClick={ handleClickSettings }>
            <ListItemIcon>
              <SettingIcon />
            </ListItemIcon>
            <ListItemText>
              Settings
            </ListItemText>
          </MenuItem>
      </Tooltip>

        <MenuItem onClick={ handleLogout }>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>

    </Menu>
  )
};

export default UserMenu;