import { isLoggedIn, syncBackgroundWithTheme, getThemeParams, fetchDaylightHours, getTimeTuple, SunriseSunsetJson, TimeTuple, converTimeTupleToDate, convertUTCDateToLocalDate} from 'utils';
import {  useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Main from "Main";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, useTheme, createTheme, Theme } from "@mui/material/styles";
import ThemeGenerator from 'shared/themes/ThemeGenerator';
// import { ToastProvider } from 'react-toast-notifications';
import { ColorModeContext} from 'contexts/ColorModeContext';

import snowTree from "assets/images/snow-tree-1.jpg";
import christmasTree from "assets/images/christmas-tree-snowing.jpg";
import christmasNight from "assets/images/christmas-night-mini.jpg";


import accountApi from 'app/api/accountApi';
import React from 'react';
import ChristmasTheme2 from 'shared/themes/ChistmasTheme2';
import ChristmasTheme from 'shared/themes/ChristmasTheme';
import DefaultTheme from 'shared/themes/DefaultTheme';




const App = () => {
  const [ updateSettings ] = accountApi.useUpdateAccountSettingsMutation();
  const { 
    colorMode: [ mode, toggleColorMode ], 
    userTouched: [ touched, setTouched],
    userThemeName: [ themeName, setThemeName ],
  } = React.useContext(ColorModeContext);

  const [ coords, setCoords ] = React.useState<GeolocationCoordinates | null>(null);

  
  React.useEffect(syncColorModeWithDaytime, [ mode, toggleColorMode, touched, setTouched, coords ]);

  const credentials = useSelector((state: RootState) => state.auth);
  const isUserLoggedIn = React.useMemo( () => isLoggedIn(credentials), [credentials]);
  const { data: settings } = accountApi.useGetAccountSettingsQuery(credentials.id, {
    skip: !isUserLoggedIn
  });

  // If a user hasn't logged in for a while, they might have outdated settings on the MongoDB server, which
  // we should update for them to the proper values using the the appropriate backend interface calls
  updateLegacySettings();

  const theme = React.useMemo(() => {
    let useableTheme: Theme;
    if(themeName === 'christmas') {
      useableTheme = createTheme(ChristmasTheme(mode));
    } else if (themeName === 'christmas2') {
      useableTheme = createTheme(ChristmasTheme2(mode));
    } else {
      useableTheme = createTheme(DefaultTheme(mode));
    }
    
    return useableTheme;
  }, [ mode, themeName ]);


  React.useEffect(updateBackground, [ mode, theme, themeName ]);
  

  function syncColorModeWithDaytime() {
    let timerId: NodeJS.Timer | null;
    
    if (window.isSecureContext) {
      const setLocation = (loc: GeolocationPosition) => {
        setCoords(loc.coords);
      };

      if (!coords) {
        navigator.geolocation.getCurrentPosition(setLocation);
      }
  
    }

    if (coords) {
      //  Only procede attempting to track the time if we have coordinates for longitude and latitude because if we don't, we can't 
      // figure out when the user's sunrise and sunset will occur
    const asyncCheckSunlight = async () => {
      let currentDay = new Date().getDate();
      
      let sunrise: Date;
      let sunset: Date;
      let response = await fetchDaylightHours(coords!.latitude, coords!.longitude);

      if (response.ok) {
        const data: SunriseSunsetJson = await response.json();
        const sunriseTime = getTimeTuple(data, 'sunrise');
        // const sunriseTime: TimeTuple = [ 5, 30, 0];        // Troubleshooting values
        const sunsetTime = getTimeTuple(data, 'sunset');
        // const sunsetTime: TimeTuple = [ 14, 55, 0];        // Troubleshooting values

        sunrise = convertUTCDateToLocalDate(converTimeTupleToDate(sunriseTime));
        sunset = convertUTCDateToLocalDate(converTimeTupleToDate(sunsetTime));

      } else {
        // If we don't get a response, we should return since we won't be able to calculate when sunrise and sunset are
        // If we decide to use the user's last major city's coordinates (and NOT their last coordinates in general because a hack
        // that reveals a home's coordinates would be catastrophic), we would set the sunset and sunrise here and possibly a flag
        // for letting the program know not to ask for coodinates again (if the user has turned of Geolocation in the settings)
        return;
      }


      if (!touched) {
        if (sunrise < new Date() && sunset >= new Date() ) {
          if (mode !== 'light') {
            toggleColorMode('light');
          }
        } else {
          if (mode !== 'dark') {
            toggleColorMode('dark');
          }
        }
      }
      
      timerId = setInterval(async function() {
        const currentTime = new Date();

        if (currentDay !== currentTime.getDate()) {
          // update the current sunrise and sunset if the day has changed (if and only if the user has not deactivated the ability to use Geolocation)
          currentDay = currentTime.getDate();

          response = await fetchDaylightHours(coords!.latitude, coords!.longitude);

          if (response.ok) {
            const data: SunriseSunsetJson = await response.json();
            const sunriseTime = getTimeTuple(data, 'sunrise');
            const sunsetTime = getTimeTuple(data, 'sunset');
            
            sunrise = convertUTCDateToLocalDate(converTimeTupleToDate(sunriseTime));
            sunset = convertUTCDateToLocalDate(converTimeTupleToDate(sunsetTime));

          } else {
            return;
          }

          if (currentTime > sunrise) {
            setTouched(false);
          }
        }

        if (!touched) {
          if (sunrise < new Date() && sunset >= new Date() ) {
            if (mode !== 'light') {
              toggleColorMode('light');
            }
          } else {
            if (mode !== 'dark') {
              toggleColorMode('dark');
            }
          }
        }
        // console.log('tick')
        // console.log(sunrise)
        // console.log(sunset);
        // console.log(currentTime)
        // console.log(touched)
        // console.log(coords)
      }, 30_000);
    }

    asyncCheckSunlight();
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      } else timerId = null;
    }
  }
  
  function updateBackground() {
    const gradient = (bgColor: string) => `linear-gradient(${bgColor}, ${bgColor})`;

    if (themeName === 'christmas') {
      if (mode === 'dark') {
        
        // document.body.style.backgroundImage = `url(${christmasTree})`;
      } else {
      }
      const bgColor = "rgba(0, 0, 0, 0.65)";
      document.body.style.backgroundColor = bgColor;
      document.body.style.backgroundImage = `${gradient(bgColor)}, url(${christmasTree})`;

    } else if (themeName === 'christmas2') {
      if (mode === 'dark') {
        const bgColor = "rgba(0, 0, 0, 0.65)";
        document.body.style.backgroundColor = bgColor;
        document.body.style.backgroundImage = `${gradient(bgColor)}, url(${christmasNight})`;
        // document.body.style.backgroundImage = `url(${christmasNight})`;
        
      } else {
        const bgColor = "rgba(255, 255, 255, 0.65)";
        document.body.style.backgroundImage = `${gradient(bgColor)}, url(${snowTree})`;
        
      }
    } else if (themeName === 'default') {
      if (mode === 'dark') {
        // const bgColor = "#00063dee";
        // document.body.style.backgroundImage = `${gradient(bgColor)}, url(${snowTree})`;
        document.body.style.backgroundImage = ``;
      } else {
        const bgColor = "rgba(255, 255, 255, 0.65)";
        document.body.style.backgroundImage = `${gradient(bgColor)}, url(${snowTree})`;
      }
      document.body.style.backgroundColor = theme.palette.background.paper;
    } else {

    }

    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";
  }

 function updateLegacySettings() {
  if (settings?.theme) {
    let themeName = settings.theme;
    let usingLegacy = false; 
    if (themeName === 'dark') {
      usingLegacy = true;
      themeName = 'default';
    } else if (themeName === 'highContrastLight') {
      usingLegacy = true;
      themeName = 'christmas2'
      usingLegacy = true;
    } else if (themeName === 'minimalChristmas') {
      themeName = 'christmas2'
    }
    setThemeName(themeName);
    if (usingLegacy) {
      updateSettings({
        "theme": themeName,
      })
    }
  }
 }

  return (
      <ThemeProvider theme={ theme }>
        <div className="App">
          <Router>
            <Main />
          </Router>
        </div>
      </ThemeProvider>
  );
}

export default App;

