import React from "react";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Typography from "shared/components/StyledTypography"



import accountApi from "app/api/accountApi";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { isLoggedIn } from "utils";
import { Link } from "react-router-dom";
import UserMenu from "./UserMenu";

interface LoginStatusProps {
  className?: string,
};


const LoginStatus = ({className, ...props}: LoginStatusProps) => {
  const credentials = useSelector((state: RootState) => state.auth);
  

  const [ menuAnchorEl, setMenuAnchorEl ] = React.useState<HTMLButtonElement | null>(null);

  const userMenuOpen = Boolean(menuAnchorEl);


  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, Event>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  }

  if (!isLoggedIn(credentials)) {
    return(
      <Button>
        <Typography>Login</Typography>
      </Button>
    );
  }

  return(
    <Box>
      <IconButton
        onClick={ handleMenuOpen }
      >
        <Avatar sx={{ backgroundColor: 'svg.secondary'}} />
      </IconButton>
      <UserMenu 
        anchorEl={ menuAnchorEl }
        open={ userMenuOpen }
        onClose={ handleMenuClose }
      />
    </Box>
  );
}


export default LoginStatus;