import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { TextField } from "formik-mui";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";

import accountApi from "../../app/api/accountApi";
import { Field, Form, Formik } from "formik";
import history from "app/history";
// import ActionButton from "shared/styles/buttons/ActionButtons/ActionButton1";
import LoginSchema from "shared/validation/LoginSchema";

// import StyledTextField from "shared/styles/Forms/TextField";

interface LoginProps {
  className?: string;
}

const Login = ({ className, ...props }: LoginProps) => {
  const [login] = accountApi.useLoginMutation();
  const [isPasswordVisible, setPasswordVisibility] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleTogglePasswordVisibility = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <Stack padding={2} borderColor="red" alignItems="center">
      <Box
        padding={2}
        width="fit-content"
        sx={{ backgroundColor: "rgba(0,0,0,0.2)", borderRadius: 3 }}
      >
        <Box className={`Login${" " + className ?? ""}`}>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            validateOnChange={false}
            onSubmit={async (values, { setSubmitting }) => {
              // Strip any white space from the username before submitting since whitespace isn't allowed in usernames
              values.username = values.username.trim();
              const response = (await login(values)) as any;
              if (response.error) {
                console.log(response.error);
                if (response.error.status === 401) {
                  setError("Username or password is incorrect.");
                } else if (response.error.status) {
                  setError("Could not login. Server might be down.");
                }
              }
              setSubmitting(false);
            }}
          >
            {({ values, submitForm, isSubmitting }) => (
              <Form style={{ width: "content" }}>
                <Stack spacing={2} alignItems="center">
                  <Typography
                    fontWeight="bold"
                    variant="h4"
                    color="typography.primary"
                  >
                    Login
                  </Typography>
                  <Field
                    component={TextField}
                    id="Username"
                    title="Username"
                    name="username"
                    width="240px"
                    InputProps={{
                      sx: { width: "320px" },
                    }}
                  />
                  <Field
                    type={isPasswordVisible ? "text" : "password"}
                    component={TextField}
                    id="Password"
                    title="Password"
                    name="password"
                    InputProps={{
                      sx: { width: "320px" },
                      endAdornment: (
                        <InputAdornment position="end">
                          {/* <Toolbar> */}
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {isPasswordVisible ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                          {/* </Toolbar> */}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <section className="FormButtons"> */}
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ width: "320px" }}
                    onClick={() => submitForm()}
                    endIcon={
                      isSubmitting ? (
                        <CircularProgress color="warning" size={22} />
                      ) : (
                        <LoginIcon />
                      )
                    }
                  >
                    Login
                  </Button>
                  <Button
                    disableElevation
                    color="primary"
                    variant="outlined"
                    sx={{ width: "320px" }}
                    onClick={() => history.push("/create_account")}
                  >
                    Create Account
                  </Button>
                  {/* <ActionButton action={ () => submitForm() }>Login</ActionButton>
                    <ActionButton action={ () => history.push("/create_account") }>Create Account</ActionButton> */}
                  <button hidden type="submit"></button>
                  {/* </section> */}
                </Stack>
                <Stack textAlign="center" m={2}>
                  {<Typography color="error">{error}</Typography>}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Stack>
  );
};

export default Login;
