import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import MenuIcon from "@mui/icons-material/Menu";
import { userApi }  from "app/api/userApi";
import listApi from "app/api/listApi";

import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { closeItemEditor, toggleNavDrawer } from "features/ui/uiSlice";
import LoginStatus from "shared/Login/LoginStatus";
import { useLocation } from "react-router-dom";

import Typography from "shared/components/StyledTypography";
import MaterialUISwitch from "shared/components/MuiSwitch";
import { ColorModeContext } from "contexts/ColorModeContext";
import { useMediaQuery, useTheme } from "@mui/material";


interface HeaderProps {
  className?: string,
}

const Header = ({className, ...props}: HeaderProps) => {
  const { 
    colorMode: [ colorMode, toggleColorMode ],
    userTouched: [ touched, setTouched ],
    userThemeName: [ themeName, setThemeName ],
  } = React.useContext(ColorModeContext);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const isNavDrawerOpen = useSelector((state: RootState) => state.ui.isNavDrawerOpen);
  const location = useLocation();
  const listId = useSelector((state: RootState) => state.lists.currentListId);
  const { data: listData } = listApi.useGetListByIdQuery(listId);

  let name: string | null = null;

  if (location.pathname.includes('/lists/')) {
    if (listData) {
      const { name: username } = listData;
      name = username;
    }
  } else if (location.pathname.includes('/profile')) {
    name = "Profile Settings";
    // name = "Profile";
  } else if (location.pathname.includes('/settings')) {
    name = "Settings";
  }



  
  

  const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement, Event>) => {
    dispatch(toggleNavDrawer());
    dispatch(closeItemEditor());
  };


  const handleChangeColorMode = () => {
    toggleColorMode();
    setTouched(true);
  };


  // Add themes later

  const stripeColor1 = '#CC231E'
  const stripeColor2 = '#fff'

  let headerStyles = {};
  let titleColor = 'typography.secondary';
  let menuIconColor = 'svg.primary';
  let headerHighlights = 'primary';
  // let style: any = {
  //   background: `repeating-linear-gradient(
  //     ${45}deg,
  //     ${stripeColor1},
  //     ${stripeColor1} ${10}px,
  //     ${stripeColor2} ${10}px,
  //     ${stripeColor2} ${20}px
  //   )`
  // };

  if (themeName === 'christmas' && colorMode === 'light') {
    headerStyles = {
      background: `repeating-linear-gradient(
        ${45}deg,
        ${stripeColor1},
        ${stripeColor1} ${10}px,
        ${stripeColor2} ${10}px,
        ${stripeColor2} ${20}px
      )`
    };
    titleColor = 'svg.secondary';
    menuIconColor = 'svg.secondary';
    headerHighlights = 'typography.secondary';
  };

  return(
    <AppBar position='sticky' sx={{ ...headerStyles, textAlign: 'center'  }}>
      <Toolbar>
        <Box sx={{ backgroundColor: headerHighlights, width: 'auto', m: 0.25,  borderRadius: 1 }}>
          <IconButton sx={{ color: menuIconColor }}
            onClick={ handleMenuToggle }
            size='large'
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Stack flexGrow={ 1 } justifyContent='center' alignItems='center'>
          <Box sx={{ backgroundColor: headerHighlights, width: 'auto', p: 0.5, paddingLeft: 2, paddingRight: 2, borderRadius: 1 }}>
            <Typography variant={isXs ? 'body1' : 'h5'} sx={{ 
              color: titleColor,
              fontWeight: isXs ? 'bold' : 'normal'
            }}>
              { name }
            </Typography>
          </Box>
        </Stack>
        <MaterialUISwitch
          checked={ colorMode === 'dark' }
          onChange={ handleChangeColorMode }
        />
        <LoginStatus />
      </Toolbar>

    </AppBar>
  )
}

// <header className={ className }>
//   <MenuButton isActive={ isNavDrawerOpen } action={ () => handleMenuToggle() }className="MenuButton"/>
  
//   <StyledLoginStatus />
// </header>

export default Header;