import Box from "@mui/material/Box";
import listApi from "../../app/api/listApi";

import ListMissing from "./ListMissing";
import AddItemInput from "./AddItemInput";
import Listing from "./Listing";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { setCurrentItem, setCurrentListId } from "features/lists/listsSlice";
import { hideAddItem, openItemEditor, setItemEditorMode } from "features/ui/uiSlice";
import { CircularProgress } from "@mui/material";


interface ListViewProps {
  className?: string,
  listId?: string,
}

interface Params {
  listId: string,
}

const ListView = ({className, ...props}: ListViewProps) => {
  const dispatch = useDispatch();
  const credentials = useSelector((state: RootState) => state.auth);
  const showAddItemInput = useSelector((state:RootState) => state.ui.isAddItemInputOpen);
  // const { listId } = useParams<Params>();
  const { listId } = useParams();

  if (listId == null) { 
    return (<ListMissing />);
  }
  
  const { data: listData, isLoading, isFetching, isError } = listApi.useGetListByIdQuery(listId, {
    pollingInterval: 60_000,
  });

  if (isError) {
    return (
      <Box>
        An error occurred while trying to load data. Do you have mobile data? Or are you on airplane mode?
      </Box>  
    )
  }
  
  if (isLoading) {
    return (
      <Box display='inline-flex' width='100%' height='calc(100vh - 128px)' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Box>
    );
  }


  dispatch(setCurrentListId(listId));
  
  // const { name } = listData;

  return (
    <Box>
      <Box className={ className } 
        width="100%" 
        height={`calc(100vh - 128px)`}
        overflow='scroll'
      >
        {/* <h1 className="Title">{ name }</h1> */}
        <Listing data={ listData } isFetching={ isFetching }/>
      </Box>
      <Formik
        initialValues={{
          itemToAdd: "",
          listId: listId,
        }}
        onSubmit={ 
          (values, {setSubmitting, resetForm }) => {
            // await addItem(values);
            dispatch(setItemEditorMode('create'));
            dispatch(
              setCurrentItem({
                name: values.itemToAdd,
                _id: undefined,
                notes: undefined,
                claimants: undefined,
                disallowedViewers: undefined,
                creator: credentials.id,
                owner: undefined,
                status: undefined
              })
            );
            dispatch(openItemEditor());
            dispatch(hideAddItem());

            resetForm();
          }
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <AddItemInput
              isActive={ showAddItemInput }
              disabledSend={ isSubmitting } 
              userid={ credentials.id }
              listid={ listId }
            />
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ListView;