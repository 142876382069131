import tree2 from "assets/images/christmas-tree-snowing.jpg";
// Tree is for mobile use
// import tree3 from "assets/images/christmas-light-village.jpg";
// #c60000
// #004a00

const Christmas = {
  primary: "rgba(73, 0,0, 0.5)",
  secondary: "#730000",
  tertiary: "white",
  quaternary: "#730000",
  accent: "white",
  highlight: "white",
  headerImage: null,
  backgroundImage: tree2,
  hideInsetColor: true,
  // #4ABEF1
};


export default Christmas;