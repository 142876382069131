import * as Yup from "yup";

const ItemSchema = Yup.object({
  name: Yup.string().required("Please enter a list name."),
  itemId: Yup.string().optional(),
  listId: Yup.string().required("Please specify the list id."),
  creator: Yup.string().required("Please specify the item creator."),
  disallowedViewers: Yup.array().of(Yup.string()).optional(),
  notes: Yup.array().of(Yup.object({ note: Yup.string().required("Please specify note if submitting notes") })).optional(),
});

export default ItemSchema;