import * as Yup from "yup";

const ListSchema = Yup.object({
  listId: Yup.string().optional(),
  name: Yup.string().required("Please enter a list name."),
  groupId: Yup.string().optional(),
  privacy: Yup.string().required("You must select the privacy level for this list."),
  kind: Yup.string().required("You must select the kind of list."),
  users: Yup.array().of(Yup.string())
});

export default ListSchema;