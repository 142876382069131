import React from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer, { SwipeableDrawerProps } from "@mui/material/SwipeableDrawer";
import Typography from "shared/components/StyledTypography"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserList from "shared/Lists/List";



interface NavDrawerProps extends SwipeableDrawerProps {
  className?: string;
  isActive?: boolean;
  lists: Array<UserList>;
};

const NavigationDrawer = ({ className, lists, ...props}: NavDrawerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = (listId: string | undefined) =>
  (event: React.MouseEvent<any, Event>) => {
    if (typeof listId !== 'undefined') {
      navigate(`/lists/${listId}`);
      props.onClose(event);
    }
  }

  return(
    <SwipeableDrawer {...props}>
      <List>
        {
          lists.map((list, index) => (
            <React.Fragment key={ list._id }>
              <ListItem key={ list._id }>
                <ListItemButton onClick={ handleNavigate(list._id) }>
                  <ListItemText>
                    <Typography fontWeight='bold'>
                      { list.name }
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              {/* </Link> */}
              </ListItem>
            { index !== lists.length - 1 ? <Divider variant='middle' component="li" /> : null }
            </React.Fragment>
          ))
        }
      </List>
    </SwipeableDrawer>
  );
}

export default NavigationDrawer;