import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const API_ADDRESS = `${API_DOMAIN}/api/v1`;



const baseQuery = fetchBaseQuery({
  baseUrl: `${API_ADDRESS}`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token != null) {
      headers.set('Authorization', `BEARER ${token}`);
    }
    return headers;
  }
});


const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: ['Account', 'User', 'ItemList', 'Group'],
  endpoints: () => ({}),
});

export default api;
