import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import  listApi  from "app/api/listApi";
import MinimalistChristmasTheme from "shared/themes/Christmas"
import ChristmasTheme from "shared/themes/Christmas2";
import DarkTheme from "shared/themes/Dark";
import HighContrastLight from "shared/themes/HighContrastLight";


export enum EditorMode {
  'CREATE',
  'EDIT'
};

const initialState = {
  isNavDrawerOpen: false,
  isItemEditorOpen: false,
  itemMode: 'create',
  listMode: 'create',
  isAddItemInputOpen: true,
  isListEditorOpen: false,
  theme: DarkTheme,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleNavDrawer(state) {
      state.isNavDrawerOpen = !state.isNavDrawerOpen;
    },

    closeNavDrawer(state) {
      state.isNavDrawerOpen = false;
    },

    openNavDrawer(state) {
      state.isNavDrawerOpen = true;
      // state.isItemEditorOpen = false;
    },

    openItemEditor(state) {
      state.isItemEditorOpen = true;
      // state.isNavDrawerOpen = false;
    },

    closeItemEditor(state) {
      state.isItemEditorOpen = false;
    },

    toggleItemEditor(state) {
      state.isItemEditorOpen = !state.isItemEditorOpen;
    },

    showAddItem(state) {
      state.isAddItemInputOpen = true;
      
    },

    hideAddItem(state) {
      state.isAddItemInputOpen = false;
    },

    toggleAddItem(state) {
      state.isAddItemInputOpen = !state.isAddItemInputOpen;
    },

    setItemEditorMode(state, action: PayloadAction<'create' | 'edit'>) {
      state.itemMode = action.payload;
    },

    openListEditor(state) {
      state.isListEditorOpen = true;
    },

    closeListEditor(state) {
      state.isListEditorOpen = false;
    },

    toggleListEditor(state) {
      state.isListEditorOpen = !state.isListEditorOpen;
    },

    setListEditorMode(state, action: PayloadAction<'create' | 'edit'>) {
      state.listMode = action.payload;
    },

    
    setTheme(state, action: PayloadAction<string>) {
      let theme = DarkTheme;
      switch(action.payload) {
        case "highContastLight":
          theme = HighContrastLight;
          break;
        case "minimalChristmas":
          theme = MinimalistChristmasTheme;
          break; 
        case "christmas":
          theme = ChristmasTheme;
          break; 
        case "dark":
        default:
          theme = DarkTheme;
        
        }
      state.theme = {...theme}
    }
  
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      listApi.endpoints.createList.matchFulfilled, 
      (state) => {
        state.isListEditorOpen = false;
      }
    )
  }

});


export const {  closeNavDrawer, openNavDrawer, toggleNavDrawer, 
  closeItemEditor, openItemEditor, toggleItemEditor, 
  showAddItem, hideAddItem, toggleAddItem,
  setItemEditorMode,
  closeListEditor, openListEditor, toggleListEditor,
  setListEditorMode,
  setTheme
  
} = uiSlice.actions;

export default uiSlice.reducer;