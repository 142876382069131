import Tooltip, { TooltipProps  } from "@mui/material/Tooltip";

const DelayedTooltip = ({ children, ...rest }: TooltipProps) => {
  return(
    <Tooltip 
      enterDelay={600} 
      enterNextDelay={400}
      enterTouchDelay={500}
      leaveTouchDelay={600}
      { ...rest }
    >
      <span>
        { children }
      </span>
    </Tooltip>
  );
};


export default DelayedTooltip;