import api from "./api";
import { clearCredentials, Credentials, setCredentials } from "features/auth/authSlice";

export interface LoginResult {
  error?: any;
  data: any;
  meta: any;
}


const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountSettings: builder.query({
      query: () => `/account/settings`,
      providesTags: ['Account']
    }),

    updateAccountSettings: builder.mutation({
      query: (body) => ({
        url: `/account/settings/update`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Account']
    }),

    updatePassword: builder.mutation({
      query: (body) => ({
        url: `/account/change_password`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Account']
    }),

    getAccountGroups: builder.query({
      query: () => `/account/groups`,
      providesTags: ['Account', 'Group']
    }),

    getAccountLists: builder.query({
      query: () => `/account/lists`,
      providesTags: ['Account', 'ItemList'],
    }),

    login: builder.mutation({
      async queryFn(credentials, queryApi, extraOptions, baseQuery) {
        let response  = await baseQuery({
          url: `/account/login`,
          method: 'POST',
          body: credentials
        });

        if (response.error) {
          return { error: { status: 401, data: "Could not login" } }
        }

        queryApi.dispatch(setCredentials(response.data as Credentials));
        return response;
      },
      invalidatesTags: ['ItemList', 'Group', 'User']
    }),

    logout: builder.mutation({
      async queryFn(args: null | undefined, queryApi, extraOptions, baseQuery) {
        let response  = await baseQuery({
          url: `/account/logout`,
          method: 'POST',
        });

        if (response.error) {
          return { error: { status: 400, data: "Could not logout" } }
        }

        queryApi.dispatch(clearCredentials());
        return response;
      },
      invalidatesTags: ['Account', 'ItemList', 'Group', 'User']
    }),

    createAccount: builder.mutation({
      query: (body) => ({
        url: `/account/create`,
        method: 'POST',
        body,
        responseHandler: async (response) => await response.text()
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        
        await cacheDataLoaded;

        const { username, passwordNew } = arg;
        dispatch(accountApi.endpoints.login.initiate({
          username,
          password: passwordNew
        }));
        
      },
      invalidatesTags: ['ItemList', 'Group', 'User']
    })

  }),
});

export default accountApi;
