// import tree1 from "assets/images/christmas-tree-lights.jpg";
// import tree2 from "assets/images/christmas-tree-snowing.jpg";
// import tree3 from "assets/images/christmas-light-village.jpg";
// #c60000
// #004a00

const Christmas = {
  primary: "white",
  secondary: "white",
  tertiary: "#004a00",
  quaternary: "white",
  accent: "#c60000",
  highlight: "#c60000",
  headerImage: null,
  backgroundImage: null,
};


export default Christmas;