import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { TextField, TextFieldProps } from "formik-mui";
import { Field, FieldArray } from "formik";
import Toolbar from "@mui/material/Toolbar";
import Typography from "shared/components/StyledTypography"
import  InputAdornment from "@mui/material/InputAdornment";
import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@emotion/react";
import { useTheme } from "@mui/material";

import DownIcon from "@mui/icons-material/ArrowDownward";
import UpIcon from "@mui/icons-material/ArrowUpward";
import RemoveIcon from "@mui/icons-material/Cancel";
// import AddIcon from "@mui/icons-material/AddComment";
import AddComment from "@mui/icons-material/AddComment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { TransitionGroup } from "react-transition-group";
import { v4 as uuidv4 } from "uuid";

const TextArea = ({...props}: TextFieldProps) => <TextField multiline {...props} />;


interface FieldProps {
  id: string;
  name: string;
  title?: string | null;
  targets: Array<Target>;
  // as: string | null;
  values: Array<any>;
  className?: string;
  useSwap?: boolean | null;
  useRemove?: boolean | null;
  useAdd?: boolean | null;
  freezeValues?: boolean | null;
  toList?: boolean | null;
};

interface Target {
  title: string | null;
  name: string;
  kind: string;
};

const TextFieldArray = ({className, targets, values, ...props}: FieldProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  

  return (
    <Stack className={ className } flexDirection='column'
      spacing={1}
      sx={{ paddingTop: 2, paddingBottom: 1 }}
    >
      <Collapse in={Boolean(values.length)}>
        <Typography variant='h5' sx={{ paddingBottom: 1 }}>Notes</Typography>
      </Collapse>
      {
      
        <FieldArray name={props.name}>
        { ({push, remove, swap}) => (
          <>
          <TransitionGroup>
          { values.map((value, idx) => {
            return (
              <Collapse key={idx}>
                <Stack 
                  width={ '100%' }
                  key={idx} id={`Row-${idx}`} 
                  className={`${props.toList ? "Row ListItem" : "Row"}` }
                  flexDirection='row'
                >
                  <Box className="FieldInputs" width={ matches ? "100%" : 'auto'} sx={{ ".MuiFormControl-root": { width: matches ? "100%" : '480px'} }}>
                  {
                    targets.map((column, columnIdx) => {
                      if (props.toList && columnIdx === 0) {
                        value = `${"• "} ${value[column.name]}`
                      } else {
                        value = value[column.name]
                      }
                      
                      return(
                        <Field component={ TextArea } key={`${column.name}${columnIdx}`}
                        InputProps={{ endAdornment: (
                          <InputAdornment position="end">
                          { props.useRemove 
                            ? 
                              <IconButton
                                size='large'
                                onClick={ () => {
                                  remove(idx);
                                  
                                } } 
                              >
                                <RemoveIcon />
                              </IconButton> 
                            :
                              null
                          }
                          </InputAdornment>
                        )}}
                          disabled={ props.freezeValues || props.toList }
                          // type="texa" 
                          width={ matches ? "100%" : 'auto'}
                          name={`${props.name}[${idx}].${column.name}`} 
                          value={ value }
                        />
                      );
                    })
                  }
                  </Box>
                  <Toolbar className="FieldButtons">
                    {/* { props.useRemove 
                      ? 
                        <IconButton
                          size='large'
                          onClick={ () => remove(idx) } 
                        >
                          <RemoveIcon />
                        </IconButton> 
                      :
                        null
                    } */}
                    { props.useSwap 
                      ? 
                        <IconButton 
                          onClick={ () => { 
                            swap(idx, idx + 1 )
                            if (idx !== values.length - 1) {}
                          }} >
                          <DownIcon />
                        </IconButton>
                      :
                        null
                    }
                    { props.useSwap
                      ? 
                        <IconButton 
                          onClick={ () => { 
                            if (idx !== 0) {
                              swap(idx, idx - 1)
                            }
                          }}>
                          <UpIcon />
                        </IconButton> 
                      :
                        null
                    }
                  </Toolbar>
                </Stack>
              </Collapse>
            );
          })}
          <Toolbar className="ManageButtons" disableGutters>
            { props.useAdd
              ?
                <Button variant='outlined'
                  startIcon={ 
                    <AddComment /> 
                  }
                  onClick={ () => push({note:"", id: uuidv4()})}>
                    Add Note
                    {/* <Typography>Add {props.title ? props.title : null}</Typography> */}
                </Button>
              :
                null
            }
          </Toolbar>
        </TransitionGroup>
        </>
      )}
      </FieldArray>
      }
      
    </Stack>
  )
}

export default TextFieldArray;