import { PaletteMode } from "@mui/material";
import { amber, grey, deepOrange, indigo, red, purple} from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { nightSky } from "./palette/colors";


const DefaultTheme = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // bgColor: "#fff",
        typography: {
          primary: "#000",
          secondary: "#fff"
        },
        svg: {
          primary: '#eee',
          secondary: grey[500],
        }
      }
      : {
          // palette values for dark mode
          // bgColor: nightSky.main,
          primary: deepOrange,
          divider: grey['A200'],
          background: {
            default: '#fff',
            paper: nightSky.main,
          },
          text: {
            primary: '#fff',
            secondary: deepOrange[300],
          },
          typography: {
            primary: "#fff",
            secondary: "#fff"
          },
          svg: {
            secondary: deepOrange[300],
          }
        }),
  },
});

export default DefaultTheme;