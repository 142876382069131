import Box from "@mui/material/Box";

import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { isLoggedIn } from "utils";

import NavigationDrawer from "shared/navigation/NavigationDrawer";
import ListView from "shared/Lists/ListView";
import Login from "shared/Login/Login";
import accountApi from "app/api/accountApi";
import List from "shared/Lists/List";
import CreateAccountPanel from "shared/Login/CreateAccountPanel";


import { Routes, Route, Navigate } from "react-router";
import ItemEditor from "shared/Lists/Items/ItemEditor";
import LandingPage from "LandingPage";
import Settings from "shared/Login/Settings";
import ListEditor from "shared/Lists/ListEditor";


import { closeItemEditor, closeListEditor, closeNavDrawer, openItemEditor, openListEditor, openNavDrawer } from "features/ui/uiSlice";

import Header from "Header";
import { useAppDispatch } from "app/hooks";

interface MainProps {
  className?: string
};

const Main = ({className, ...props}: MainProps) => {
  const dispatch = useAppDispatch();
  const credentials = useSelector((state: RootState) => state.auth);
  const isListEditorOpen = useSelector((state: RootState) => state.ui.isListEditorOpen);
  const isNavDrawerOpen = useSelector((state: RootState) => state.ui.isNavDrawerOpen);
  const isItemEditorOpen = useSelector((state: RootState) => state.ui.isItemEditorOpen);

  let { data: lists } = accountApi.useGetAccountListsQuery(credentials.id, {
    skip: !isLoggedIn(credentials),
    pollingInterval: 30_000,
  });

  if (!isLoggedIn(credentials)) {
    return (
      <Box className={`Main ${className}`} height='100vh'>
        <Routes>
          <Route path="/create_account"  element={ <CreateAccountPanel /> } />
          <Route path="*" element={ <Login /> } />
        </Routes>
      </Box>
    )
  }

  if (lists == null ) return null;

  let userMadeLists: List[] = lists.filter((list: List) => list.creator === credentials.id);
  

  let firstList: List | null = null;
  if (userMadeLists.length) {
    firstList = userMadeLists[0];
  }



  const handleOpenNavDrawer = () => {
    dispatch(openNavDrawer());
  };

  const handleCloseNavDrawer = () => {
    dispatch(closeNavDrawer());
  };

  const handleOpenItemEditor = () => {
    dispatch(openItemEditor());
  };

  const handleCloseItemEditor = () => {
    dispatch(closeItemEditor());
  };

  const handleOpenListEditor = () => {
    dispatch(openListEditor());
  };

  const handleCloseListEditor =() => {
    dispatch(closeListEditor());
  };

  const SliderUpPaperProps = { 
    sx: { height: `calc(${100}vh - ${64}px)` },
  };

  return (
    <Box className={`Main ${className}`}>
      <Header />
      <NavigationDrawer
        anchor='left'
        PaperProps={{ 
          sx: { 
            ...SliderUpPaperProps.sx,
            width: `${100}vw`,
            marginTop: '64px',
          },
        }}
        open={ isNavDrawerOpen } 
        onOpen={ handleOpenNavDrawer }
        onClose={ handleCloseNavDrawer }
        lists={ lists }
      />
      <ItemEditor 
        PaperProps={ SliderUpPaperProps }
        anchor='bottom'
        open={ isItemEditorOpen }
        onOpen={ handleOpenItemEditor }
        onClose={ handleCloseItemEditor }
      />
      <ListEditor
        PaperProps={ SliderUpPaperProps }
        anchor='bottom'
        open={ isListEditorOpen } 
        onOpen={ handleOpenListEditor }
        onClose={ handleCloseListEditor }
      />

      <Box>
        <Routes>
          <Route path="/lists/:listId" element={ <ListView /> } />
          <Route path="/settings" element={ <Settings /> }/>
          <Route path="*" element={ <Navigate to={`/lists/${firstList?._id}`} />} /> 
        </Routes>
      </Box>

    </Box>
  )
}

export default Main;