import api from "./api";
import { createEntityAdapter } from "@reduxjs/toolkit";
import User from "shared/Users/User";
import UserRole from "shared/Users/UserRole"

const UserAdapter = createEntityAdapter<User>({
  selectId: (user) => user._id,
  sortComparer: (a, b) => a.username.localeCompare(b.username)
});

const UserRoleAdapter = createEntityAdapter<UserRole>({
  selectId: (user) => user.userid,
  sortComparer: (a, b) => {
    if (a.role == null) return -1
    if (b.role == null) return -1
    else {
      return a.role.localeCompare(b.role)
    }
  }
});

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    
    getUserById: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: (id, error, args) => [{ type: 'User', id}],
      transformResponse(response: User) {
        return UserAdapter.upsertOne(
          UserAdapter.getInitialState(),
          response
        )
      }
    }),

    getUsersByGroupId: builder.query({
      query: (groupId) => `/users/group/${groupId}`,
      // providesTags: (id, error, args) => [{ type: 'User'}],
      transformResponse(response: UserRole[]) {
        return UserRoleAdapter.upsertMany(
          UserRoleAdapter.getInitialState(),
          response
        )
      }
    })

  })
});