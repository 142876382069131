import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authSlice from '../features/auth/authSlice';
import listsSlice from 'features/lists/listsSlice';
import uiSlice from 'features/ui/uiSlice';
import api from './api/api';


const composedState = () => {
  // Get authentication from from browser local storage
  let localAuth = localStorage.getItem('auth');
  
  let authentication = { 
  username: null, 
  token: null,
  id: null,
  iss: null,
};

  if (localAuth != null) {
    let credentials = JSON.parse(localAuth);
    // Users who were logged in before expiration token were administered should have their credentials removed from storage
    if (credentials.exp === undefined) {
      localStorage.removeItem('auth');
    }
    // Check to see if the token is expired, if so we should log the user out.
    if (Date.now() < credentials.exp) {
      authentication = credentials
    } else {
      localStorage.removeItem('auth');
    }
  }
  

  return { auth: authentication };
};

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [api.reducerPath]: api.reducer,
    auth: authSlice,
    lists: listsSlice,
    ui: uiSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  preloadedState: composedState(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
