import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Token = string;
export type Username = string | null;
export type Issuance = string;
export type Id = string;

export interface Credentials {
  token?: Token | null;
  username?: Username;
  id: Id | null,
  iss?: Issuance | null,
  exp?: number,
}

let initialState: Credentials = {
  token: null,
  username: null,
  id: null,
  iss: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<Credentials>) {
      localStorage.setItem('auth', JSON.stringify(action.payload));
      state.token = action.payload.token;
      state.username = action.payload.username;
      state.id = action.payload.id;
      state.iss = action.payload.iss;
      state.exp = action.payload.exp;
    },

    clearCredentials(state) {
      localStorage.removeItem('auth');
      state.token = null;
      state.username = null;
      state.id = null;
      state.iss = null;
      state.exp = undefined;
    },
  },
});

export const { clearCredentials, setCredentials } = authSlice.actions;

export default authSlice.reducer;
