import api from "./api";
// import { createEntityAdapter } from "@reduxjs/toolkit";
// import List from "shared/Lists/List";
import history from "app/history";


// const ListAdapter = createEntityAdapter<List>({
//   selectId: (list) => list._id,
//   sortComparer: (a, b) => {
//     return a.created.toString().localeCompare(b.created.toString())  
//   }
// });


const listApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getListsByGroup: builder.query({
      query: (groupId) => `/lists/groups/${groupId}`,
      providesTags: ['ItemList', 'Group']
    }),

    getListById: builder.query({
      query: (id) => `/lists/${id}`,
      providesTags: (result, error, id) => [{type: 'ItemList', id}]
    }),

    createList: builder.mutation({
      query: (body) => ({
        url: `/lists/create/${body.groupId}`,
        method: "POST",
        body,
        responseHandler: (response) => response.text()
      }),
       async onCacheEntryAdded (arg, { dispatch, getCacheEntry, cacheDataLoaded}) {
         try {
            let payload = (await cacheDataLoaded).data;
            history.push(`/lists/${payload}`);
         }
         catch { }
      },
      invalidatesTags: ['ItemList', 'Account' ]
    }),

    removeList: builder.mutation({
      query: (body) => ({
        url: `/lists/remove/${body.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ItemList', 'Group']
    }),

    editList: builder.mutation({
      query: (body) => ({
        url: `/lists/edit/${body.listId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ItemList', 'Group']
    }),

    addItemToList: builder.mutation({
      query: (body) => ({
        url: `/lists/items/add/${body.listId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (results, error, { listId }) => [{type: 'ItemList', id: listId }, 'ItemList']
    }),

    claimItemOnList: builder.mutation({
      query: (body) => ({
        url: `/lists/items/claim/${body.listId}/${body.itemId}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { listId }) => [{ type: 'ItemList', id: listId}]
    }),

    removeItemFromList: builder.mutation({
      query: (body) => ({
        url: `/lists/items/remove/${body.listId}/${body.itemId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { listId }) => [{type: "ItemList", id: listId} ]
    }),

    editItemOnList: builder.mutation({
      query: (body) => ({
        url: `/lists/items/update/${body.listId}/${body.itemId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { listId }) => [{type: "ItemList", id: listId} ]
    }),

    clearList: builder.mutation({
      query: (body) => ({
        url: `/lists/clear/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, { listId }) => [{type: "ItemList", id: listId} ]
    }),
  }),
});

export default listApi;
