import DarkTheme from "shared/themes/Dark";
import HighContrastLight from "shared/themes/HighContrastLight";
import MinimalistChristmasTheme from "shared/themes/Christmas";
import ChristmasTheme from "shared/themes/Christmas2";

type Token = string | null;
type Username = string | null;
type Id = string | null;
type Issuance = string | null ;


interface Credentials {
  token?: Token;
  username?: Username;
  id?: Id,
  iss?: Issuance,
  exp?: number,
}

export const isLoggedIn = (credentials: Credentials): boolean => {
  return (
    typeof(credentials.token) !== 'undefined' && credentials.token !== null
    // credentials.username != null
  );
};

export const isCurrentUser = (credentials: Credentials, userId?: Id): boolean => {
  if (userId == null) return false;
  return credentials.id === userId;
}

export const getThemeParams = (themeName: string) => {
  let themeParams = DarkTheme;
  switch(themeName) {
    case "highContrastLight":
      themeParams = HighContrastLight;
      break;
    case "minimalChristmas":
      themeParams = MinimalistChristmasTheme;
      break; 
    case "christmas":
      themeParams = ChristmasTheme;
      break; 
    case "dark":
    default:
      themeParams = DarkTheme;
    }

    return themeParams;
}


export const syncBackgroundWithTheme = (theme: any) => {
  if (theme) {
      document.body.style.backgroundColor = theme.background.general.color;
  }
}

export const fetchDaylightHours = async (
  latitude: number,
  longitude: number,
  date?: string | undefined

): Promise<Response> => {
  date = date ?? 'today';
  // const latitude = 36.7201600;
  // const longitude = -4.4203400;

  const url = `https://api.sunrise-sunset.org/json?lat=${latitude}&lng=${longitude}&date=${date}`;
  return fetch(url);
}


export type SunriseSunsetStatusCode = 'OK' | 'INVALID_DATE' | 'INVALID_REQUEST' | 'UNKNOWN_ERROR';
export interface SunriseSunsetJson {
  results:
  {
    sunrise: string;
    sunset: string;
    solar_noon: string;
    day_length: number;
    civil_twilight_begin: string;
    civil_twilight_end: string;
    nautical_twilight_begin: string;
    nautical_twilight_end: string;
    astronomical_twilight_begin: string;
    astronomical_twilight_end: string;
  };
    status: SunriseSunsetStatusCode;
};


export type TimeTuple = [ number, number, number ];
export type SunRiseSunsetKey = 'sunrise' | 'sunset';

export const getTimeTuple = (data: SunriseSunsetJson, key: SunRiseSunsetKey): TimeTuple => {
  const time : TimeTuple = [0, 0, 0];
  const target = data.results[key]
  
  let [ hoursString, minutesString, rest ] = target.split(":");
  let [ secondsString, AM_PM ] = rest.split(" ");

  let seconds = parseInt(secondsString, 10);
  let minutes = parseInt(minutesString, 10);
  let hours = parseInt(hoursString, 10);


  if (AM_PM === "PM" && hours !== 12) {
    hours += 12;
  }

  time[0] = hours;
  time[1] = minutes;
  time[2] = seconds;

  return time;
    
}

export const converTimeTupleToDate = (time: TimeTuple) => {
  const date = new Date();
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time[0],
    time[1],
    time[2]
  );
} 

export const convertUTCDateToLocalDate = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}