import Box from "@mui/material/Box";
import SwipeableDrawer, { SwipeableDrawerProps } from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "shared/components/DelayedTooltip";
import Typography from "shared/components/StyledTypography"
import { TextField, Select } from "formik-mui";
import { Field, Formik, Form } from "formik";
import { userApi } from "app/api/userApi";
import UserCheckbox from "shared/Forms/UserCheckbox";
import listApi from "app/api/listApi";

import SubmitIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/ArrowBack";


import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import ListSchema from "shared/validation/ListSchema";
import { SelectorOption } from "shared/Forms/Selector";
import List from "./List";
import { setListEditorMode, closeListEditor } from "features/ui/uiSlice";
import UserRole from "shared/Users/UserRole";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// import { useToasts } from "react-toast-notifications";



interface ListEditorProps extends SwipeableDrawerProps {
  className?: string,
  listId?: List,
};

const privacyOptions: Array<SelectorOption> = [
  { name: "Default", value: "default" },
  { name: "Friends", value: "friends" },
  { name: "Private", value: "private" },
];

const kindOptions: Array<SelectorOption> = [
  { name: "Standard", value: "standard"},
  { name: "Wishlist", value: "wishlist" },
  { name: "Todo List", value: "todo"},
];


const ListEditor = ({className, ...props}: ListEditorProps) => {
  const dispatch = useDispatch();
  const credentials = useSelector((state: RootState) => state.auth);
  const listMode = useSelector((state: RootState) => state.ui.listMode);
  const listId = useSelector((state: RootState) => state.lists.currentListId)
  // const { addToast } = useToasts();
  const { data: users } = userApi.useGetUsersByGroupIdQuery("6175d476170c3b3c03c94cba");
  const { data: you } = userApi.useGetUserByIdQuery(credentials.id);
  const { data: list } = listApi.useGetListByIdQuery(listId, {
    skip: (listMode === 'create' || listId == null)
  });

  const [ editList ] = listApi.useEditListMutation()
  const [ createList ] = listApi.useCreateListMutation();
  
  if (you == null) return null;
  if (credentials.id == null) return null;
  if (listMode === 'edit' && list == null) return null;

  const handleListEditorClose = () => {
    dispatch(closeListEditor());
    dispatch(setListEditorMode('create'));
  };


  return(
    <SwipeableDrawer className={ className } {...props}>
      <Formik
        enableReinitialize
        initialValues={{
          "listId": list ? list._id : null,
          "name": list ? list.name : `${ you?.entities[credentials.id].username }'s Wishlist`,
          "kind": list ? list.kind : "wishlist",
          "users": list ? list.users.map((user: UserRole) => user.userid) : [],
          "privacy": list ? list.privacy : "default",
          "groupId": list ? list.group.id : "6175d476170c3b3c03c94cba",
          "groupName": list ? list.group.name : "The Great House of Kreiser"
        }}
        validationSchema={ListSchema}
        onSubmit={ async (values, { setSubmitting }) => {
          
          if (listMode === 'create') {
            await createList(values);
          } 
          else {
            await editList(values);
          }
          handleListEditorClose();
          setSubmitting(false);
        } }
        >
          {({values, errors, isSubmitting, submitForm }) => ( 
            <Form>
              <Stack alignItems='center' spacing={2} sx={{ marginTop: '10px'}}>
                <Tooltip 
                  title={<Box>The name of the list that you and shared users will see</Box>}
                  enterDelay={500}
                  enterNextDelay={300}
                >
                  <span>
                    <Field component={ TextField } id="name" label='List Name' type="text" name="name" />
                  </span>
                </Tooltip>

                <Tooltip 
                  title={<Box>The group to which this list belongs; by default this is the name of the list</Box>}
                  enterDelay={500}
                  enterNextDelay={300}
                >
                  <span>
                    <Field component={ TextField } disabled id="name" type="text" label='List Group' name="groupName" />
                  </span>
                </Tooltip>

                <Tooltip 
                  title={<Box>The lists privacy setting, which determines who can see your list</Box>}
                  enterDelay={500}
                  enterNextDelay={300}
                >
                  <span>
                    <Field component={ Select } 
                      disabled 
                      id='privacy' 
                      label='Privacy'
                      name='privacy'
                      // value={ values.privacy }
                      // options={ privacyOptions }
                    >
                      { 
                        privacyOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                        ))
                      }
                    </Field>
                  </span>
                </Tooltip>

                <Tooltip 
                  title={<Box>The lists privacy setting, which determines who can see your list</Box>}
                  enterDelay={500}
                  enterNextDelay={300}
                >
                  <span>
                    <Field component={ Select } disabled 
                      id='kind' 
                      label='List Kind'
                      title="List Kind" 
                      name='kind'
                      // value={ values.kind }
                    >
                      { kindOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                      ))}
                    </Field>
                  </span>
                </Tooltip>
              </Stack>
              <Box>
                <Typography variant='h5'>Share with</Typography>

                { users
                  ? 
                    <Grid className="UserOptions" container spacing={2}>
                      
                      { Object.values(users.entities)
                        .filter((user) => user?.userid !== credentials.id)
                        .map((user, index) => (
                          <Grid xs={3} key={`user.user_id${index}`}>
                            <UserCheckbox key={ `user.user_id${index}` } name={"users"} userId={ user?.userid ?? '' } /> 
                          </Grid>
                        ) ) }
                    </Grid> 
                  : 
                    <Box>
                      No Users were found. You have not selected a group or you need to manually add users.
                    </Box>
                }
              </Box>
              <Stack className="FormButtons">
                <Toolbar>
                  <Button startIcon={ <CancelIcon /> }
                    onClick={ () => handleListEditorClose() }>
                    Back
                  </Button>
                  <Button startIcon={ <SubmitIcon /> }
                    onClick={ async () => {
                    if (Object.keys(errors).length > 0) {
                      let errorMessage = Object.values(errors);
                      // addToast(errorMessage, {
                      //   appearance: 'error',
                      //   autoDismiss: true,
                      // })
                    }
                    submitForm() } 
                  }>
                    {/* { listMode === 'create' ? "Create List" : 'Save' } */}
                    Save
                  </Button>
                </Toolbar>
              </Stack>
            </Form>
          )}
        </Formik>
    </SwipeableDrawer>
  )
} 


export default ListEditor;