import Typography, { TypographyProps} from "@mui/material/Typography";



const StyledTypography = ({sx, children, ...rest}: TypographyProps) => {
  return (
    <Typography {...rest} sx={{ color: 'typography.primary', ...sx }}>
      { children }
    </Typography>
  );
};

export default StyledTypography;