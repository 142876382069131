import { FunctionComponent } from "react";

const ListMissing: FunctionComponent = (props) => {
  return (
    <div>
      The requested list could not be found. Perhaps it has been deleted?
    </div>
  );
}


export default ListMissing;